


































































































































































































import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import {
  Booking,
  BookingStatus,
  Scene,
  PaymentGateway,
  PaymentGatewayGroup
} from "@/resources/interfaces";
import { confirm, promptInput } from "@/helpers/sweetAlert";
import { BookingResource } from "@/resources";
import sleep from "@/helpers/sleep";
import iconv from "iconv-lite";
import moment from "moment";
import { watchPayments } from "@/helpers/watchPayment";
import { PaymentDialog } from "@/components";

@Component({ components: { PaymentDialog } })
export default class BookingPaymentBar extends Vue {
  @Prop()
  value!: Booking;

  @Prop()
  price!: number;

  @Prop()
  priceInPoints!: number;

  @Prop({ type: Boolean })
  priceUpdating!: boolean;

  @Prop()
  paymentGateway!: string;

  @Prop()
  useBalance!: string;

  @Prop()
  bandsPrintable!: number;

  @Prop()
  customerSearchTerm!: string;

  @Prop({ type: Boolean, default: false })
  saving!: boolean;

  @Prop({ type: Function })
  toggleUseBalance!: Function;

  @Prop({ type: Function })
  usePaymentGateway!: Function;

  @Prop({ type: Function })
  usingPaymentGateway!: Function;

  @Prop({ type: Object })
  watchPaymentsState!: { enabled: boolean };

  today = moment().format("YYYY-MM-DD");
  showPaymentDialog = false;
  showRefundDialog = false;
  amountRefund = NaN;
  kidsRefund = NaN;
  adultsRefund = NaN;
  refundReason = "";
  couponWrittenOff = false;
  refundType: "full" | "partialAmount" | "partialTimes" = "full";
  refundSubmitting = false;

  get booking() {
    if (this.value.refundReason) {
      this.refundReason = this.value.refundReason;
    }
    if (this.value.amountRefund) {
      this.refundType = "partialAmount";
      this.amountRefund = this.value.amountRefund;
    } else if (this.value.kidsRefund) {
      this.refundType = "partialTimes";
      this.kidsRefund = this.value.kidsRefund;
      this.adultsRefund = this.value.adultsRefund || NaN;
    } else {
      this.refundType = "full";
    }
    return this.value;
  }

  set booking(v: Booking) {
    this.$emit("input", v);
  }

  get gateways() {
    const gateways = [
      "scan",
      "customerscan",
      /*"dianping", "shouqianba", */ "cash" /*"pr", "cr"*/
    ];
    if ([Scene.PARTY, Scene.OTHER].includes(this.booking.type)) {
      gateways.push(PaymentGateway.Pos);
    }
    if (this.booking.card?.isContract) {
      gateways.push("ar");
    }
    return gateways;
  }

  get bookingValidated() {
    if (this.booking.id) return true;
    if (!this.booking.store) return false;
    if (this.priceUpdating) return false;
    if (this.saving) return false;

    // needs pay, but no gateway
    if (
      !this.paymentGateway &&
      (this.price || this.priceInPoints) &&
      (!this.booking.customer?.balance ||
        this.booking.customer.balance < (this.price || 0) ||
        !this.useBalance)
    )
      return false;
    if (this.booking.type === Scene.EVENT && !this.booking.event) return false;
    if (this.booking.type === Scene.GIFT && !this.booking.gift) return false;
    if (!this.booking.customer && this.customerSearchTerm.length !== 11)
      return false;
    return true;
  }

  get bookingCancelable() {
    if (this.booking.id && this.$user.can("DEVELOP")) return true;
    if (!this.$user.can("BOOKING_CREATE")) return false;
    if (
      this.booking.type === Scene.PARTY &&
      this.booking.status === BookingStatus.IN_SERVICE
    ) {
      return false;
    }
    return (
      this.booking.id &&
      !["canceled"].includes(this.booking.status as BookingStatus) &&
      !this.booking.providerData?.provider &&
      (this.$user.can("BOOKING_CANCEL_REVIEW") ||
        [
          BookingStatus.PENDING,
          BookingStatus.BOOKED,
          BookingStatus.IN_SERVICE,
          BookingStatus.FINISHED
        ].includes(this.booking.status || BookingStatus.PENDING) ||
        !this.booking.date)
    );
  }

  get enableExtraPaymentGateways() {
    if (!this.booking.customer || !this.booking.customer.balance) return true;
    return (
      !this.useBalance || this.booking.customer.balance < (this.price || 0)
    );
  }

  get validRefund() {
    if (this.refundSubmitting) {
      return false;
    }
    if (this.refundType !== "partialTimes") {
      this.kidsRefund = NaN;
      this.adultsRefund = NaN;
    }
    if (this.refundType !== "partialAmount") {
      this.amountRefund = NaN;
    }
    if (this.refundType === "partialAmount" && !this.amountRefund) {
      return false;
    }
    if (
      this.refundType === "partialTimes" &&
      !(this.adultsRefund || this.kidsRefund)
    ) {
      return false;
    }
    // if (this.refundType === "partialTimes") {
    //   return false;
    // }
    if (!this.refundReason) return false;
    return true;
  }

  get adultsRefundOptions() {
    const options: number[] = [];
    if (!this.kidsRefund) return options;
    let freeParentsPerKid = this.$config.freeParentsPerKid || 1;
    if (this.booking?.card?.freeParentsPerKid) {
      freeParentsPerKid = this.booking.card.freeParentsPerKid;
    }
    if (this.booking?.coupon?.freeParentsPerKid) {
      freeParentsPerKid = this.booking.coupon.freeParentsPerKid;
    }
    const freeParents = this.booking.kidsCount * freeParentsPerKid;
    const maxFreeParentsAfter =
      (this.booking.kidsCount - this.kidsRefund) * freeParentsPerKid;
    const minParentsReduce = Math.max(freeParents - maxFreeParentsAfter, 0);
    for (let n = minParentsReduce; n <= this.booking.adultsCount; n++) {
      options.push(n);
    }
    return options;
  }

  @Watch("refundType") onRefundTypeUpdate() {
    if (!["full", "partialTimes"].includes(this.refundType)) {
      this.couponWrittenOff = false;
    }
  }

  save() {
    this.$emit("save");
  }

  async cancel() {
    this.refundSubmitting = true;
    const refundable = (this.booking.payments || [])
      .filter(
        p =>
          p.paid &&
          !p.original &&
          ![
            PaymentGateway.Coupon,
            PaymentGateway.Card,
            PaymentGateway.Contract,
            PaymentGateway.CouponCard
          ].includes(p.gateway)
      )
      .reduce(
        (amount, p) =>
          +(amount + p.amount - (p.refundedAmount || 0)).toFixed(2),
        0
      );
    if (this.amountRefund > refundable) {
      this.$notify({ type: "warning", message: "退款金额不能大于未退金额" });
      this.refundSubmitting = false;
      return;
    }

    try {
      this.booking = await BookingResource.update(
        {
          id: this.booking.id,
          refundType: this.refundType,
          refundOffline: this.couponWrittenOff || undefined
        },
        {
          status: BookingStatus.CANCELED,
          refundReason: this.refundReason,
          amountRefund: this.amountRefund || undefined,
          adultsRefund: this.adultsRefund || undefined,
          kidsRefund: this.kidsRefund || undefined
        }
      );

      if (this.booking.card) {
        this.$emit("update-cards");
      }

      this.showRefundDialog = false;
      this.amountRefund = NaN;
      this.kidsRefund = NaN;
      this.refundReason = "";
      this.refundType = "full";
    } catch (e) {
      //
    }
    this.refundSubmitting = false;
  }

  async remove() {
    if (!this.booking.id) return;
    if (
      !(await confirm(
        "确定要删除这个预约？",
        `这个操作不可撤销，并且将删除这个预约和他的所有支付记录`,
        "确定删除",
        "error"
      ))
    )
      return;
    await BookingResource.delete({ id: this.booking.id });
    this.$router.go(-1);
  }

  async recover() {
    if (!this.booking.statusWas) return;
    if (
      !(await confirm(
        "确定要恢复这个预约？",
        `这将把这个预约的状态恢复为“${
          this.$bookingStatusNames[this.booking.statusWas]
        }”`,
        "确定恢复",
        "success"
      ))
    )
      return;
    this.booking = await BookingResource.update(
      { id: this.booking.id },
      { status: this.booking.statusWas }
    );
  }

  async printReceipt() {
    await BookingResource.get({ id: this.booking.id, printReceipt: true });
    this.$notify({
      message: "小票打印请求已发送",
      icon: "check",
      type: "success"
    });
    this.printLocalReceipt();
  }

  async printLocalReceipt() {
    if (
      this.booking.payments?.some(p =>
        [PaymentGateway.CouponCard, PaymentGateway.Contract].includes(p.gateway)
      )
    ) {
      return;
    }
    const esc = "\x1b";
    const gs = "\x1d";
    const lf = "\x0a";

    let data = esc + "@";
    data += `${gs}!\x21${esc}a1Liquisis` + lf + lf;
    data += `${gs}!\x01` + this.booking.store?.name + "店" + lf + lf;
    data += `${gs}!\x00${esc}a0订单编号：${this.booking.id?.substr(-6)}` + lf;
    data += `消费日期：${this.booking.date}` + lf;
    data += `消费时间：${this.booking.checkInAt}` + lf;
    data += `  手机号：${this.booking.customer?.mobile || "-"}` + lf;
    data += "-".repeat(32) + lf;
    if (this.booking.type === Scene.PLAY) {
      this.booking.kidsCount &&
        (data += `门票 儿童：${this.booking.kidsCount}` + lf);
      this.booking.adultsCount &&
        (data += `门票 成人：${this.booking.adultsCount}` + lf);
      data += "-".repeat(32) + lf;
    }
    if (this.booking.items?.length) {
      data = this.booking.items.reduce(
        (data, i) =>
          data +
          `${esc}a0${i.name}×${i.quantity}${lf}${
            i.comment ? " - " + i.comment + lf : ""
          }${esc}a2${(
            ((i.sellPrice || 0) + (i.extraPrice || 0)) *
            i.quantity
          ).toFixed(2)}元` +
          lf,
        data
      );
    }

    const payments = this.booking.payments?.filter(
      p =>
        p.paid &&
        ![PaymentGateway.CouponCard, PaymentGateway.Contract].includes(
          p.gateway
        )
    );

    if (payments) {
      data += lf + "付款方式：" + lf + `${esc}a2`;
      data = payments.reduce(
        (data, p) =>
          data +
          `${this.$gatewayNames[p.gateway]}：${p.amount.toFixed(2)}元` +
          lf,
        data
      );
    }

    const store = this.$stores.find(s => s.id === this.booking.store?.id);

    data += lf + `地址：${store?.address || ""}` + lf;
    data += `电话：${store?.phone || ""}` + lf;
    data += `${"-".repeat(32)}${esc}a1STAY WITH U`;
    data += lf.repeat(6);

    // if (new Date()) {
    //   console.log(data);
    //   return;
    // }

    this.$notify({
      message: "正在打印小票，请等待",
      icon: "priority_high",
      type: "warning",
      timeout: 1500
    });

    try {
      await this.$electron?.printReceipt(iconv.encode(data, "GBK"));
      await sleep(1500);
      this.$notify({
        message: "小票打印完毕",
        icon: "check",
        type: "success"
      });
    } catch (e) {
      console.error(e);
      this.$notify({
        message: e.message || "小票打印失败",
        icon: "close",
        type: "danger"
      });
    }
  }

  async printBands() {
    if (!this.booking.customer?.mobile) {
      this.$notify({
        message: "无用户手机号，无法打印手环",
        icon: "priority_high",
        type: "danger"
      });
      return;
    }
    const n = +(await promptInput(
      "要打印几条手环",
      `可打印手环：${this.bandsPrintable}条`,
      null,
      "question",
      "number",
      this.bandsPrintable,
      v => {
        if (v > this.bandsPrintable) return "超过可打印手环数";
        if (v <= 0) return "请填写有效数字";
      }
    ));
    if (!n) return;
    console.log("n is:", n);
    console.log("app version is", this.$electron?.app.getVersion());
    let type = "A";
    if (this.booking.coupon?.slug) {
      type += `/${this.booking.coupon.slug}`;
    }
    if (this.booking.card?.slug) {
      type += `/${this.booking.card.slug}`;
    }
    const data =
      this.$electron?.app.getVersion() >= "1.1.0"
        ? {
            name: "",
            mobile: this.booking.customer.mobile,
            time:
              this.booking.date + " " + this.booking.checkInAt?.substr(0, 5) ||
              "",
            qr: this.booking.customer.mobile.substr(-9),
            type
          }
        : {
            data1: this.booking.customer.mobile,
            data2:
              this.booking.date + " " + this.booking.checkInAt?.substr(0, 5) ||
              ""
          };
    this.$notify({
      message: "正在打印手环，请等待",
      icon: "priority_high",
      type: "warning",
      timeout: n * 1500
    });
    const store = this.$stores.find(s => s.id === this.booking.store?.id);
    const printer = store?.ticketPrinters?.find(
      p =>
        p.type === "band" &&
        p.bandType ===
          (this.booking.coupon || this.booking.card?.isContract
            ? "coupon"
            : "normal")
    );
    try {
      for (let i = 0; i < n; i++) {
        console.log("Call electron printBands:", data);
        await this.$electron?.printBands(data, printer?.sn);
        await sleep(1500);
      }
      this.booking.bandsPrinted = (this.booking.bandsPrinted || 0) + n;
      await BookingResource.update(
        { id: this.booking.id },
        { bandsPrinted: this.booking.bandsPrinted }
      );
      this.$notify({
        message: "手环打印完毕",
        icon: "check",
        type: "success"
      });
    } catch (e) {
      console.error(e);
      this.$notify({
        message: e.message || "手环打印失败",
        icon: "close",
        type: "danger"
      });
    }
  }

  async checkIn() {
    if (!(await confirm("确定已入场", "确定至少1位客人已经入场"))) return;
    this.booking = await BookingResource.update(
      { id: this.booking.id },
      { status: BookingStatus.IN_SERVICE }
    );
  }

  async checkOut() {
    if (
      !(await confirm(
        "确定已出场",
        "确定所有客人已经结束游玩离场",
        null,
        "warning"
      ))
    )
      return;
    this.booking.status = BookingStatus.FINISHED;
    this.booking = await BookingResource.save(this.booking);
  }

  async redeem() {
    if (!(await confirm("确定已兑换"))) return;
    this.booking.status = BookingStatus.FINISHED;
    this.booking = await BookingResource.save(this.booking);
  }

  async partyPay(paymentGroups: PaymentGatewayGroup[]) {
    this.booking = await BookingResource.update(
      {
        id: this.booking.id,
        arPaymentGateways: paymentGroups
          .filter(g => g.amount)
          .map(g => {
            let seg = `${g.gateway}:${g.amount}`;
            if (g.payCode) seg += `-${g.payCode}`;
            if (g.couponId) seg += `-${g.couponId}`;
            return seg;
          })
          .join(","),
        useBalance: this.useBalance ? "true" : undefined
      },
      {}
    );
    this.showPaymentDialog = false;
    watchPayments(this.booking.payments || [], this.watchPaymentsState).then(
      async payments => {
        if (payments.length) {
          this.booking = await BookingResource.get({
            id: this.$route.params.id
          });
        }
      }
    );
  }

  goPartyFoodCashier() {
    if (!this.booking.customer) return;
    this.$router.push(
      `/booking/food-cashier?customer=${this.booking.customer.id}&party=${this.booking.id}`
    );
  }

  goPartyLabCashier() {
    if (!this.booking.customer) return;
    this.$router.push(
      `/booking/lab-cashier?customer=${this.booking.customer.id}&party=${this.booking.id}`
    );
  }

  goPartyRetailCashier() {
    if (!this.booking.customer) return;
    this.$router.push(
      `/booking/retail-cashier?customer=${this.booking.customer.id}&party=${this.booking.id}`
    );
  }

  async partyStart() {
    if (!(await confirm("确定将派对设置为进行中", `将可以进行点单、结账操作`)))
      return;
    this.booking = await BookingResource.update(
      {
        id: this.booking.id
      },
      { status: BookingStatus.IN_SERVICE }
    );
  }
}
