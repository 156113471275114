


































































































































































































































































































































import moment from "moment";
import List from "@/components/List";
import Component from "vue-class-component";
import { BookingResource, http } from "@/resources";
import {
  Payment,
  BookingQuery,
  Booking,
  Scene,
  User,
  Gift,
  Event
} from "@/resources/interfaces";
import eventBus from "@/helpers/eventBus";

@Component({
  filters: {
    paidAmount(payments: Payment[]) {
      return payments
        .filter(p => p.paid)
        .map(p => p.amount)
        .reduce((sum, amount) => sum + amount, 0);
    }
  }
})
export default class BookingList extends List<Booking> {
  name = "booking";
  resource = BookingResource;
  totalAmount: {
    adultsCount: number;
    kidsCount: number;
    amountPaid: number;
  } = { adultsCount: NaN, kidsCount: NaN, amountPaid: NaN };
  type: Scene = Scene.PLAY;
  searchQuery: BookingQuery = {};
  async queryData() {
    const queriedData = await (List as any).options.methods.queryData.call(
      this
    );
    if (!queriedData) return;
    const [adultsCount, kidsCount, amountPaid] = (
      queriedData.$headers["total-amount"] || "-,-,-"
    ).split(",") as number[];
    this.totalAmount.amountPaid = amountPaid;
    this.totalAmount.adultsCount = adultsCount;
    this.totalAmount.kidsCount = kidsCount;
    return queriedData;
  }
  showDetail(item: Booking) {
    this.$router.push(`/booking/${item.type}/${item.id}`);
  }
  showCreate(type?: Scene) {
    this.$router.push(`/booking/${type}/add`);
  }
  goToCustomer(customer: User) {
    if (!customer) return;
    this.$router.push(`/user/${customer.id}`);
  }
  goToEvent(event: Event) {
    this.$router.push(`/event/${event.id}`);
  }
  goToGift(gift: Gift) {
    this.$router.push(`/gift/${gift.id}`);
  }
  downloadItemsSheet() {
    const params: Record<string, any> = {
      token: window.localStorage.token,
      ...this.query
    };

    const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null)
      .map(key => `${key}=${params[key]}`)
      .join("&");
    window.location.href =
      process.env.VUE_APP_API_BASE + "/booking-items-sheet?" + queryString;
  }
  onStringScanned(s: string) {
    console.log("BookingList:onStringScanned", s);
    this.searchQuery.customerKeyword = s;
  }
  async initDoor() {
    if (!this.$user.store) return;
    const data = { init: true };
    await http.post(`/store/${this.$user.store.id}/door`, data);
    this.$notify({
      message: "操作成功",
      icon: "check",
      type: "success"
    });
    this.$user.store = { ...this.$user.store, doorsInitialized: true };
  }
  activated() {
    eventBus.$on(["stringScanned", "qrcodeScanned"], this.onStringScanned);
  }
  deactivated() {
    eventBus.$off(["stringScanned", "qrcodeScanned"], this.onStringScanned);
  }
  created() {
    this.type = this.$route.params.type as Scene;
    this.searchQuery = {
      type: this.type,
      date:
        this.$route.query.customer ||
        this.$route.query.event ||
        this.$route.query.product
          ? undefined
          : moment().format("YYYY-MM-DD"),
      status: [],
      customer: this.$route.query.customer as string,
      event: this.$route.query.event as string,
      customerKeyword: "",
      product: this.$route.query.product as string,
      cardSlug: this.$route.query.cardSlug as string
    };
  }
}
