






































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Watch, Component } from "vue-property-decorator";
import { confirm, alert } from "@/helpers/sweetAlert";
import {
  CardType,
  CardTypeReward,
  PaymentGateway,
  Store,
  bookingScenes
} from "@/resources/interfaces";
import { CardTypeResource, CardResource } from "@/resources";
import { Poster, Editor, StoreSelect } from "@/components";
import castEmbedded from "@/helpers/castEmbedded";

const switchKeys = [
  "isGift",
  "isContract",
  "isLimited",
  "isCombo",
  "openForClient",
  "openForReception",
  "openForBar",
  "cancelOnExpire",
  "activeOnGiftReceive",
  "oneTimeCheckIn",
  "adultAsKid"
] as const;

type SwitchKey = typeof switchKeys[number];

@Component({
  components: {
    Poster,
    Editor,
    StoreSelect
  }
})
export default class CardTypeDetail extends Vue {
  cardType: Partial<CardType> = {
    id: "",
    stores: [],
    customerTags: [],
    posterUrls: [],
    balancePriceGroups: [],
    rewards: [],
    scenes: [],
    enabled: true,
    couponType: undefined,
    paymentStore: undefined
  };
  rewardCardTypes: CardType[] = [];
  typeRelatedProperties: Array<keyof CardType> = [
    "times",
    "balance",
    "maxTimes",
    "minKids",
    "freeParentsPerKid",
    "couponType",
    "overPrice",
    "discountPrice",
    "discountRate",
    "fixedPrice",
    "partnerUrl"
  ];
  searchCardTypesTimeout?: number;

  showIssueDialog = false;
  issueParams = {
    gateway: PaymentGateway.Agency,
    quantity: 0
  };

  showGenerateQrDialog = false;
  generateQrParams: { stores: Store[] } = {
    stores: []
  };
  bookingScenes = bookingScenes;

  get valid() {
    return (
      this.cardType.title &&
      this.cardType.slug &&
      this.cardType.price !== undefined
    );
  }
  get cardTypeGroups() {
    return switchKeys.filter(k => this.cardType[k]);
  }
  set cardTypeGroups(v: Array<SwitchKey>) {
    switchKeys.forEach(k => {
      this.cardType[k] = v.includes(k);
    });
    if (!this.cardType.isContract) {
      this.cardType.channel = null;
    }
  }
  async save() {
    this.cardType = await CardTypeResource.save(
      castEmbedded(this.cardType, ["paymentStore"])
    );

    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });

    if (this.$route.params.id === "add") {
      this.$router.replace(`/card-type/${this.cardType.id}`);
    }
  }
  async remove() {
    if (
      !(await confirm(
        "确定要删除这个卡片种类",
        `这个操作不可撤销，已购该卡客户将不会受影响`,
        "确定删除",
        "error"
      ))
    )
      return;
    await CardTypeResource.delete({ id: this.cardType.id });
    this.$router.go(-1);
  }
  async clone() {
    if (
      !(await confirm(
        "确定要复制这个卡种",
        `当前卡种的修改将不被保存`,
        "复制并离开",
        "warning"
      ))
    )
      return;
    this.$router.push("/card-type/add?clone=" + this.cardType.id);
  }
  async updateIssued() {
    if (
      !(await confirm(
        "确定覆盖所有已发卡券？",
        `将覆盖以下信息：标题、门店、赠券、结算比例、满/减/折/价、优惠商品、内容详情`,
        "覆盖",
        "warning"
      ))
    )
      return;
    const { n, nModified } = ((await CardTypeResource.update(
      { id: this.cardType.id, updateIssued: true },
      {}
    )) as unknown) as { n: number; nModified: number };
    alert(
      "覆盖已完成",
      `找到${n}张已发卡券，同步了${nModified}张卡券信息`,
      null,
      "success"
    );
  }
  @Watch("cardType.type") onCardTypeTypeUpdate(type: string, typeWas: string) {
    if (typeWas && type && type !== typeWas) {
      for (const k of this.typeRelatedProperties) {
        delete this.cardType[k];
      }
    }
    if (type === "coupon" && !this.cardType.couponType) {
      this.cardType.couponType = "discount";
    }
  }
  @Watch("cardType.start") onCardTypeStartUpdate(v: Date | string) {
    if (v && (!v.constructor || v.constructor.name !== "Date")) {
      this.cardType.start = new Date((this.cardType as CardType).start);
    }
  }
  @Watch("cardType.end") onCardTypeEndUpdate(v: Date | string) {
    if (v && (!v.constructor || v.constructor.name !== "Date")) {
      this.cardType.end = new Date((this.cardType as CardType).end);
    }
  }
  @Watch("cardType.dayType") onCardTypeDayTypeUpdate(v: false | string) {
    if (v === false) {
      this.cardType.dayType = null;
    }
  }
  addPoster() {
    this.cardType.posterUrls?.push("");
  }
  removePoster(index: number) {
    this.cardType.posterUrls = this.cardType.posterUrls
      ?.slice(0, index)
      .concat(this.cardType.posterUrls.slice(index + 1));
  }
  addBalanceGroup() {
    if (!this.cardType.balancePriceGroups) {
      this.cardType.balancePriceGroups = [];
    }
    this.cardType.balancePriceGroups.push({});
    this.$set(
      this.cardType,
      "balancePriceGroups",
      this.cardType.balancePriceGroups
    );
  }
  removeBalancePriceGroup(index: number) {
    if (!this.cardType.balancePriceGroups) {
      return;
    }
    this.cardType.balancePriceGroups = this.cardType.balancePriceGroups
      .slice(0, index)
      .concat(this.cardType.balancePriceGroups.slice(index + 1));
  }
  addReward() {
    if (!this.cardType.rewards) {
      this.cardType.rewards = [];
    }
    this.cardType.rewards.push({
      rewardAt: "checkIn",
      rewardEveryTime: false
    });
    this.$set(this.cardType, "rewards", this.cardType.rewards);
  }
  removeReward(index: number) {
    if (!this.cardType.rewards) {
      return;
    }
    this.cardType.rewards = this.cardType.rewards
      .slice(0, index)
      .concat(this.cardType.rewards.slice(index + 1));
  }
  searchRewardCardTypes(v: string) {
    if (typeof v !== "string" || !v) return;
    if (this.searchCardTypesTimeout) {
      clearTimeout(this.searchCardTypesTimeout);
    }
    this.searchCardTypesTimeout = window.setTimeout(async () => {
      this.rewardCardTypes = await CardTypeResource.query({
        // type: "coupon",
        enabled: true,
        title: v
      });
    });
  }
  selectRewardCardType(reward: CardTypeReward, item: CardType) {
    reward.slug = item.slug;
    reward.title = item.title;
    this.rewardCardTypes = [];
  }
  async issueCardsToRootUserWithRid() {
    if (
      !(await confirm(
        `确定生成${this.issueParams.quantity}份${this.cardType.slug}吗？`,
        "将直接生成支付记录，操作不可撤销，生成的券码请及时复制"
      ))
    ) {
      return;
    }
    const result = (await CardResource.create(
      { slug: this.cardType.slug },
      {
        issueCardsToRootUserWithRid: true,
        quantity: this.issueParams.quantity,
        gateway: this.issueParams.gateway
      }
    )) as any;
    this.showIssueDialog = false;
    await alert("卡券已生成", result.rids.join(" "), "复制券码", "success");
    this.$clipboard(result.rids.join("\n"), "券码");
  }
  async downloadQrs() {
    const urls = (await CardTypeResource.get({
      id: this.cardType.slug,
      generateQrForStores:
        this.generateQrParams.stores.map(s => s.id).join(",") || "ALL"
    })) as any;
    urls.forEach((url: string, index: number) => {
      window.open(url, index.toString());
    });
    // window.open(
    //   process.env.VUE_APP_API_BASE +
    //     `/card-type/${this.cardType.slug}?generateQrForStores=${
    //       this.generateQrParams.stores.map(s => s.id).join(",") || "ALL"
    //     }`
    // );
    this.generateQrParams = { stores: [] };
    this.showGenerateQrDialog = false;
  }
  async mounted() {
    if (this.$route.params.id !== "add") {
      this.cardType = await CardTypeResource.get({
        id: encodeURIComponent(this.$route.params.id)
      });
    }
    if (this.$route.query.clone) {
      const origin = await CardTypeResource.get({
        id: this.$route.query.clone
      });
      this.cardType = { ...origin };
      delete this.cardType.createdAt;
      delete this.cardType.updatedAt;
      delete this.cardType.id;
      delete this.cardType._id;
    }
  }
}
