













































































































import List from "@/components/List";
import { CardTypeResource } from "@/resources";
import { CardType } from "@/resources/interfaces";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component
export default class CardTypeList extends List<CardType> {
  name = "card-type";
  resource = CardTypeResource;
  saveSearchQueryKeys = [
    "type",
    "scenes",
    "stores",
    "openForClient",
    "openForReception",
    "openForBar",
    "isContract"
  ];
  autoCompletes = [{ key: "customerKeyword", minLength: 4 }];
  @Watch("searchQuery", { deep: true }) onLocalSearchQueryUpdate(
    n: Record<string, any>
  ) {
    if (!["coupon", "times", "period"].includes(n.type)) {
      n.scenes = undefined;
    }
  }
  async created() {
    this.searchQuery = {
      type: "",
      scenes: "",
      stores: "",
      openForClient: "",
      openForReception: "",
      openForBar: "",
      isContract: "",
      enabled: true,
      ...this.searchQuery
    };
  }
}
