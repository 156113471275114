


















































































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { confirm } from "@/helpers/sweetAlert";
import {
  Coupon,
  CardType,
  CardTypeReward,
  Client
} from "@/resources/interfaces";
import { CouponResource, CardTypeResource, http } from "@/resources";
import { StoreSelect } from "@/components";
import CopyId from "@/components/CopyId.vue";

const switchKeys = [
  "oneTimeCheckIn",
  "keepTimesOnAmendCard",
  "adultAsKid",
  "writeOffAsStoreFlow"
] as const;

type SwitchKey = (typeof switchKeys)[number];

@Component({ components: { StoreSelect, CopyId } })
export default class CouponDetail extends Vue {
  coupon: Partial<Coupon> = {
    id: "",
    stores: [],
    adultsCount: 1,
    kidsCount: 0,
    hours: null,
    priceThirdParty: 0,
    price: 0,
    enabled: true,
    rewards: []
  };
  clients: Client[] = [];
  rewardCardTypes: CardType[] = [];
  get couponGroups() {
    return switchKeys.filter(k => this.coupon[k]);
  }
  set couponGroups(v: Array<SwitchKey>) {
    switchKeys.forEach(k => {
      this.coupon[k] = v.includes(k);
    });
  }
  get readonly() {
    if (this.$user.can("BOOKING_ALL_STORE")) {
      return false;
    }
    return true;
  }
  async save() {
    this.coupon = await CouponResource.save(this.coupon);

    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });

    if (this.$route.params.id === "add") {
      this.$router.replace(`/coupon/${this.coupon.id}`);
    }
  }
  async remove() {
    if (
      !(await confirm(
        "确定要删除这个优惠",
        `这个操作不可撤销`,
        "确定删除",
        "error"
      ))
    )
      return;
    await CouponResource.delete({ id: this.coupon.id });
    this.$router.go(-1);
  }
  addReward() {
    if (!this.coupon.rewards) {
      this.coupon.rewards = [];
    }
    this.coupon.rewards.push({
      rewardAt: "checkIn",
      rewardEveryTime: false
    });
    this.$set(this.coupon, "rewards", this.coupon.rewards);
  }
  removeReward(index: number) {
    if (!this.coupon.rewards) {
      return;
    }
    this.coupon.rewards = this.coupon.rewards
      .slice(0, index)
      .concat(this.coupon.rewards.slice(index + 1));
  }
  async searchRewardCardTypes(v: string) {
    if (typeof v !== "string") return;
    this.rewardCardTypes = await CardTypeResource.query({
      // type: "coupon",
      enabled: true,
      title: v
    });
  }
  selectRewardCardType(reward: CardTypeReward, item: CardType) {
    reward.slug = item.slug;
    reward.title = item.title;
    this.rewardCardTypes = [];
  }
  goPayment() {
    this.$router.push(`/payment?coupon=${this.coupon.id}`);
  }
  async mounted() {
    if (this.$route.params.id !== "add") {
      this.coupon = await CouponResource.get({ id: this.$route.params.id });
      if (this.coupon.start) {
        this.coupon.start = new Date(this.coupon.start);
      }
      if (this.coupon.end) {
        this.coupon.end = new Date(this.coupon.end);
      }
    }
    this.clients = (await http.get("/client")).data as Client[];
  }
}
