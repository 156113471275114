


























































































import Vue from "vue";
import Component from "vue-class-component";
import { Product } from "@/resources/interfaces";
import { http, ProductResource } from "@/resources";
import { StoreSelect } from "@/components";
import { Watch } from "vue-property-decorator";
import moment from "moment";

@Component({ components: { StoreSelect } })
export default class StockLogDetail extends Vue {
  // products: Product[] = [];
  date: string | null = null;
  dateEnd: string | null = null;
  timeStart: string = "00:00";
  timeEnd: string = "24:00";
  store: string | null = null;
  dept: string | null = null;
  stats: {
    name: string;
    category: string;
    amountPaid: number;
    quantity: number;
  }[] = [];

  productsSearchResult: Product[] | Promise<Product[]> = [];
  searchTimeout?: number;
  productSearchTerm = "";
  productSearchCategory = "";
  categories: string[] = [];

  // @Watch("productSearchTerm")
  // async searchProduct(v: string) {
  //   if (this.searchTimeout) {
  //     clearTimeout(this.searchTimeout);
  //   }
  //   this.searchTimeout = window.setTimeout(async () => {
  //     this.productsSearchResult = ProductResource.query({
  //       name: v,
  //       type: "food"
  //     });
  //   }, 200);
  // }

  get condition() {
    return [
      /*this.products, */ this.date,
      this.dateEnd,
      this.store,
      this.dept,
      this.timeStart,
      this.timeEnd
    ];
  }

  get filteredStats() {
    return this.stats.filter(i => {
      if (this.productSearchTerm && !i.name.includes(this.productSearchTerm)) {
        return false;
      }
      if (
        this.productSearchCategory &&
        i.category !== this.productSearchCategory
      ) {
        return false;
      }
      return true;
    });
  }

  get totalAmountPaid() {
    return this.stats.reduce(
      (total, stat) => total + (stat.amountPaid || 0),
      0
    );
  }

  get filteredAmountPaid() {
    return this.filteredStats.reduce(
      (total, stat) => total + (stat.amountPaid || 0),
      0
    );
  }

  @Watch("condition") onConditionUpdate() {
    this.getStats();
  }

  // async selectProduct(product: Product) {
  //   this.products.push(product);
  //   // this.rememberProducts();
  //   this.productSearchTerm = "";
  // }

  // removeProduct(item: any) {
  //   this.products = this.products.filter(p => p.pospalUid !== item._id);
  //   // this.rememberProducts();
  // }

  // rememberProducts() {
  //   window.localStorage.setItem(
  //     "booking.itemsStats.products",
  //     this.products.map(p => p.pospalUid).join(",")
  //   );
  // }

  async getStats() {
    // if (!this.products.length) return;
    this.categories = ((await ProductResource.query({
      type: "food",
      aggregateCategories: true
    })) as unknown) as string[];
    this.categories = this.categories.filter(c => c);
    this.stats = (
      await http.get("/booking-items-stats", {
        params: {
          // products: this.products.map(p => p.id).join(","),
          date: this.date,
          dateEnd: this.dateEnd || undefined,
          store: this.store,
          dept: this.dept,
          timeStart: this.timeStart,
          timeEnd: this.timeEnd
        }
      })
    ).data;
  }

  async created() {
    this.date = moment().format("YYYY-MM-DD");
    // const productIdString = window.localStorage.getItem(
    //   "booking.itemsStats.products"
    // );
    // if (productIdString) {
    //   this.products = await ProductResource.query({
    //     _id: productIdString
    //   });
    // }
    if (this.$user.store) {
      this.store = this.$user.store.id;
    }
  }

  activated() {
    this.getStats();
  }
}
