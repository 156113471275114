





















































































































































































































































































































































































































































import Vue from "vue";
import { Watch, Component, Prop } from "vue-property-decorator";
import { confirm, promptInput } from "@/helpers/sweetAlert";
import { watchPayments } from "@/helpers/watchPayment";
import moment from "moment";
import {
  BookingResource,
  BookingPriceResource,
  UserResource,
  EventResource,
  GiftResource,
  PaymentResource,
  CardResource,
  http
} from "@/resources";
import {
  Event,
  Gift,
  Card,
  Store,
  Booking,
  Scene,
  BookingStatus,
  Payment,
  Coupon,
  PaymentGateway,
  CardStatus,
  BookingItem,
  Band,
  User
} from "@/resources/interfaces";
import {
  Membership,
  StoreSelect,
  FacesSnapshotModal,
  PaymentsCard,
  BookingsCard
} from "@/components";
import { Route, NavigationGuardNext } from "vue-router";
import eventBus from "@/helpers/eventBus";
import BindBandsDialog from "./BindBandsDialog.vue";
import LogsCard from "@/components/LogsCard.vue";
import castEmbedded from "@/helpers/castEmbedded";
import CopyId from "@/components/CopyId.vue";
import BookingPaymentBar from "./BookingPaymentBar.vue";

@Component({
  components: {
    Membership,
    StoreSelect,
    FacesSnapshotModal,
    PaymentsCard,
    BookingsCard,
    BindBandsDialog,
    LogsCard,
    CopyId,
    BookingPaymentBar
  }
})
export default class BookingDetail extends Vue {
  booking: Partial<Booking> = {
    id: "",
    payments: [],
    bands: []
  };
  price: number | null = null;
  priceInPoints: number | null = null;
  priceUpdating = false;
  customerSearchTerm = "";
  checkInCustomerSearchTerm = "";
  events: Event[] = [];
  gifts: Gift[] = [];
  customerCards: Card[] = [];
  eventSearchTerm: string | null = null;
  giftSearchTerm: string | null = null;
  paymentGateway: string | null = null;
  useBalance = true;
  today = moment().format("YYYY-MM-DD");
  showFacesSnapshotModal = false;
  watchPaymentsState = { enabled: true };
  partySubBookings: Booking[] = [];
  showBindBandsDialog = false;
  isActivated = false;
  showInternalPayments = false;
  saving = false;

  @Prop({ default: false })
  add!: boolean;

  get bandsPrintable() {
    return (
      +(this.booking.kidsCount || 0) +
      +(this.booking.adultsCount || 0) -
      +(this.booking.bandsPrinted || 0)
    );
  }

  get priceRelatedBookingProperties() {
    return [
      this.booking.card?.id,
      this.booking.coupon?.id,
      this.booking.kidsCount,
      this.booking.adultsCount,
      this.booking.event?.id,
      this.booking.gift?.id,
      this.booking.quantity,
      this.booking.price,
      this.booking.store?.id,
      this.booking.date
    ].join();
  }

  get availableCards() {
    return this.customerCards.filter(card => this.cardAvailable(card));
  }

  get availableCoupons() {
    return this.$coupons.filter(coupon => {
      if (coupon.scene !== this.booking.type) {
        return false;
      }
      if (
        coupon.productNos?.length &&
        !this.booking.items
          ?.map(i => i.no)
          .some(no => coupon.productNos?.includes(no || ""))
      ) {
        return false;
      }
      return (
        !coupon.stores.length ||
        coupon.stores.includes(this.booking.store?.id || "")
      );
    });
  }

  get couponGroups() {
    const groups = this.$config.couponGroups || [];
    return groups
      .map(g => {
        const coupons = this.availableCoupons
          .filter(coupon => {
            return coupon.title.includes(g.name);
          })
          .map(c => ({
            ...c,
            title: c.title.replace(g.name, "").replace(/【|】/g, "")
          }));
        return {
          coupons,
          name: g.name,
          color: g.color
        };
      })
      .filter(g => g.coupons.length);
  }

  get standaloneCoupons() {
    const groups = this.$config.couponGroups || [];
    return this.availableCoupons.filter(coupon => {
      return groups.every(g => !coupon.title.includes(g.name));
    });
  }

  get cardHeaderClass() {
    if (!this.booking.type) return "";
    if (this.booking.type === Scene.OTHER) return "md-card-header-black";
    return "md-card-header-" + this.booking.type;
  }

  get cardHeaderIcon() {
    if (!this.booking.type) return "";
    const map = {
      [Scene.PLAY]: "timer",
      [Scene.EVENT]: "event",
      [Scene.GIFT]: "card_giftcard",
      [Scene.RETAIL]: "storefront",
      [Scene.FOOD]: "fastfood",
      [Scene.PARTY]: "cake",
      [Scene.CARE]: "child_care",
      [Scene.MALL]: "shopping_cart",
      [Scene.OTHER]: "extension",
      [Scene.CARD]: "",
      [Scene.BALANCE]: "",
      [Scene.PERIOD]: ""
    };
    return map[this.booking.type];
  }

  get cardHeaderTitle() {
    if (!this.booking.type) return "";
    const map = {
      [Scene.PLAY]: "票务订单",
      [Scene.EVENT]: "活动订单",
      [Scene.GIFT]: "礼品订单",
      [Scene.RETAIL]: "零售订单",
      [Scene.FOOD]: "餐饮订单",
      [Scene.PARTY]: "派对订单",
      [Scene.MALL]: "电商订单",
      [Scene.CARE]: "托管订单",
      [Scene.OTHER]: "其他订单",
      [Scene.CARD]: "",
      [Scene.BALANCE]: "",
      [Scene.PERIOD]: ""
    };
    return map[this.booking.type];
  }

  get isBookingPriceFixed() {
    const isFixed = this.booking.card?.fixedPrice !== undefined;
    if (isFixed) {
      this.booking.price = this.booking.card?.fixedPrice;
    } else {
      // this.booking.price = undefined;
    }
    return isFixed;
  }

  get allowChangeAdultsCount() {
    return (
      !this.booking.id ||
      (this.booking.adultsCount || 0) < (this.maxAdultsCount || 0) ||
      (this.booking.type === Scene.PARTY &&
        this.booking.status !== BookingStatus.FINISHED) ||
      this.$user.can(
        "BOOKING_ALL_STORE",
        "PLAY_BOOKING",
        "BOOKING_CANCEL_REVIEW"
      )
    );
  }

  get maxAdultsCount() {
    if (!this.booking.id) return undefined;
    const kids = this.booking.kidsCount || 0;
    const adults = this.booking.adultsCount || 0;
    let max = kids;
    if (this.booking.card) {
      max = this.booking.card.freeParentsPerKid * kids;
    } else if (this.booking.coupon) {
      max = this.booking.coupon.freeParentsPerKid * kids;
    }
    return Math.max(max, adults);
  }

  get store() {
    return this.$stores.find(s => s.id === this.booking.store?.id);
  }

  async save() {
    const { paymentGateway } = this;

    const paymentGatewayNames = [];

    if (this.booking.card) {
      paymentGatewayNames.push(this.booking.card.title);
    }
    if (this.booking.coupon) {
      paymentGatewayNames.push(this.booking.coupon.title);
    }
    if (
      paymentGateway !== "points" &&
      this.price &&
      this.booking.customer?.balance &&
      this.useBalance
    ) {
      paymentGatewayNames.push("账户余额");
    }
    if (paymentGateway) {
      paymentGatewayNames.push(this.$gatewayNames[paymentGateway]);
    }

    if (
      !this.booking.id &&
      this.paymentGateway !== PaymentGateway.Scan &&
      !(await confirm(
        "确定已收款/验券",
        `支付方式：${paymentGatewayNames.join("、")}`
      ))
    ) {
      return;
    }

    let payCode = "";

    if (
      !this.booking.id &&
      this.paymentGateway === PaymentGateway.Scan &&
      !(payCode = await promptInput(
        "请扫描客人的付款码",
        `支持：支付宝、微信支付`,
        "确定",
        "question",
        "text",
        "",
        v => !v && "请扫描客人的付款条形码"
      ))
    ) {
      return;
    }

    if (payCode === "camera") {
      eventBus.$emit("openQrcodeScanner");
      return;
    }

    this.saving = true;

    this.booking = await BookingResource.save(
      {
        ...this.booking,
        // @ts-ignore
        store: this.booking.store?.id,
        // @ts-ignore
        customer: this.booking.customer?.id,
        // @ts-ignore
        checkInCustomer: this.booking.checkInCustomer?.id,
        // @ts-ignore
        card: this.booking.card?.id,
        // @ts-ignore
        coupon: this.booking.coupon?.id
      },
      {
        paymentGateway,
        customerKeyword: this.customerSearchTerm,
        checkInCustomerKeyword: this.checkInCustomerSearchTerm,
        useBalance: this.useBalance,
        payCode: payCode || undefined
      }
    );

    this.saving = false;

    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });

    if (this.add) {
      this.$destroy();
      this.$router.replace(`/booking/${this.booking.type}/${this.booking.id}`);
    }
  }

  async searchCustomer(q: string, checkInCustomer = false) {
    const field = checkInCustomer ? "checkInCustomer" : "customer";
    if (!q) {
      this.booking[field] = null;
      this.$set(this.booking, field, null);
      return;
    }
    if (
      q.length < 2 ||
      (this.booking[field] && q === this.booking[field]?.mobile)
    )
      return;
    const customers = await UserResource.query({
      role: "customer",
      keyword: q
    });
    if (customers.length === 1) {
      this.booking[field] = customers[0];
      this.$set(this.booking, field, customers[0]);
      console.log(
        `${checkInCustomer ? "Check-in customer" : "customer"} set to ${
          customers[0].id
        } ${customers[0].mobile}`
      );
    } else if (this.booking[field]) {
      this.booking[field] = null;
      this.$set(this.booking, field, null);
      console.log(
        `${checkInCustomer ? "Check-in customer" : "customer"} cleared.`
      );
    }
  }

  async getEvents(q?: string) {
    console.log("GetEvents with store:", this.booking.store?.name);
    if (q && q === this.booking.event?.title) return;
    this.events = await EventResource.query({
      keyword: q,
      store: this.booking.store?.id
    });
    return this.events;
  }

  selectEvent(item: Event) {
    this.booking.event = item;
    this.eventSearchTerm = item.title;
  }

  async getGifts(q?: string) {
    if (q && q === this.booking.gift?.title) return;
    this.gifts = await GiftResource.query({
      keyword: q
      // store: this.booking.store?.id
    });
    return this.gifts;
  }

  selectGift(item: Gift) {
    (this.booking as Booking).gift = item;
    this.giftSearchTerm = item.title;
  }

  goCustomerDetail(customer?: User) {
    if (!customer) return;
    this.$router.push(`/user/${customer.id}`);
  }

  goProduct(productId: string | undefined) {
    if (!productId) return;
    this.$router.push(`/product/${productId}`);
  }

  destroy() {
    this.$destroy();
    this.$router.back();
  }

  async updateBookingPrice() {
    if (this.booking.id) return;
    if (
      this.booking.type &&
      [Scene.PLAY, Scene.EVENT, Scene.PARTY].includes(this.booking.type) &&
      (this.booking.adultsCount === undefined ||
        this.booking.kidsCount === undefined)
    )
      return;
    if (this.booking.type === Scene.FOOD && this.booking.id) return;
    console.log(
      "Update booking price:",
      JSON.stringify(this.priceRelatedBookingProperties)
    );
    this.priceUpdating = true;
    try {
      const { price, priceInPoints } = await BookingPriceResource.create(
        castEmbedded(this.booking, ["customer", "checkInCustomer"])
      );
      this.price = price || null;
      this.priceInPoints = priceInPoints || null;
      this.priceUpdating = false;
    } catch (e) {
      this.price = this.priceInPoints = null;
    }
  }

  cardAvailable(card: Card) {
    if (card.status !== CardStatus.ACTIVATED) return false;
    if (
      card.start &&
      new Date(card.start) > moment(this.booking.date).endOf("day").toDate()
    )
      return false;
    if (
      this.booking.type === Scene.PLAY &&
      (["times", "period"].includes(card.type) ||
        (card.type === "coupon" && card.scenes.includes(Scene.PLAY)))
    ) {
      if (!card.stores.length) return true;
      if (
        this.booking.store?.id &&
        card.stores.includes(this.booking.store?.id)
      )
        return true;
    }
    if (this.booking.type === Scene.FOOD && card.type === "coupon") {
      if (!card.overPrice || (this.booking.price || 0) >= card.overPrice) {
        return true;
      }
    }
    if (
      this.booking.type === Scene.PARTY &&
      card.scenes.includes(Scene.PARTY)
    ) {
      return true;
    }
    return false;
  }

  useCard(card?: Card | false) {
    if (!card || this.usingCard(card)) {
      console.log("UseCard null");
      this.booking.card = null;
    } else {
      this.useCoupon(false);
      this.usePaymentGateway(false);
      if (card.freeParentsPerKid) {
        this.booking.adultsCount =
          (this.booking.kidsCount || 1) * card.freeParentsPerKid;
      }
      this.booking.card = card;
    }
  }

  usingCard(card: Card) {
    return this.booking.card?.id === card.id;
  }

  useCoupon(coupon?: Coupon | false) {
    if (!coupon || this.usingCoupon(coupon)) {
      console.log("UseCoupon null");
      this.booking.coupon = null;
    } else {
      this.useCard(false);
      this.usePaymentGateway(false);
      this.booking.coupon = this.$config.coupons?.find(c => c.id === coupon.id);
    }
  }

  usingCoupon(coupon: Coupon) {
    return this.booking.coupon?.id === coupon.id;
  }

  usePaymentGateway(gateway?: PaymentGateway | false) {
    if (!gateway || this.usingPaymentGateway(gateway)) {
      console.log("UsePaymentGateway null");
      this.paymentGateway = null;
    } else {
      this.paymentGateway = gateway;
    }
  }

  toggleUseBalance() {
    this.useBalance = !this.useBalance;
  }

  usingPaymentGateway(gateway: PaymentGateway) {
    return this.paymentGateway === gateway;
  }

  itemAmountPaidHasDiff(item: BookingItem) {
    if (item.amountPaid === undefined) return false;
    const itemPrice = +(
      ((item.sellPrice || 0) + (item.extraPrice || 0)) *
      item.quantity
    ).toFixed(2);
    return itemPrice !== item.amountPaid;
  }

  async bindBands(bands: Band[]) {
    if (!this.booking?.id) return;
    this.booking.bands = bands;
  }

  async pay(payment: Payment) {
    const booking = this.booking as Booking;
    if (
      !(await confirm(
        `确定已收款 ¥${payment.amount.toFixed(2)}`,
        null,
        "确定已收款"
      ))
    )
      return;
    await PaymentResource.update({ id: payment.id }, { paid: true });
    this.booking = await BookingResource.get({ id: booking.id });
    this.$notify({
      message:
        "收款成功，预约状态现在是：" +
        this.$bookingStatusNames[this.booking.status || ""],
      icon: "add_alert",
      type: "success"
    });
  }

  async getCustomerCards() {
    if (!this.booking.customer) {
      this.customerCards = [];
      return;
    }
    this.customerCards = await CardResource.query({
      customer: this.booking.customer.id,
      scene: this.booking.type,
      status: "activated",
      limit: false
    });
  }

  async getCustomer() {
    if (!this.booking.customer) return;
    this.booking.customer = await UserResource.get({
      id: this.booking.customer.id
    });
  }

  async checkExistingBooking() {
    if (!this.booking.customer || !this.booking.store) return;
    const bookingsExists = await BookingResource.query({
      type: this.booking.type,
      status: [BookingStatus.BOOKED, BookingStatus.IN_SERVICE].join(","),
      date: this.booking.date,
      store: this.booking.store.id,
      customer: this.booking.customer.id
    });
    if (bookingsExists.length) {
      const go = await confirm(
        "已存在订单",
        `当前用户在今天已经有${bookingsExists.length}个票务订单，是否查看`,
        "立即跳转"
      );
      if (go) {
        this.$destroy();
        this.$router.push(`/booking/play?customer=${this.booking.customer.id}`);
      }
    }
  }

  createAnother() {
    this.$router.push(`/booking/${this.$route.params.type}/add`);
  }

  async openDoor(open: string) {
    await http.post(`/store/${this.store?.id}/door`, {
      open,
      booking: this.booking.id
    });
    this.$notify({
      message: "操作成功",
      icon: "check",
      type: "success"
    });
  }

  async clone() {
    if (
      !(await confirm(
        "确定要复制这个订单",
        `当前订单的修改将不被保存`,
        "复制并离开",
        "warning"
      ))
    )
      return;
    this.$router.push(
      `/booking/${this.booking.type}/add?clone=${this.booking.id}`
    );
  }

  onMembershipUpdate() {
    this.getCustomer();
    this.getCustomerCards();
    if (this.booking.id) {
      BookingResource.get({ id: this.booking.id }).then(
        booking => (this.booking = booking)
      );
    }
  }

  onFaceUpload(data: { photo: string; faces: string[] }) {
    const { photo, faces } = data;
    if (!this.booking.photos) {
      this.booking.photos = [];
    }
    if (!this.booking.faces) {
      this.booking.faces = [];
    }
    this.booking.photos.push(photo);
    this.booking.faces = this.booking.faces.concat(faces);
    if (this.booking.id) {
      this.save();
    }
  }

  onStringScanned(s: string) {
    if (!this.booking.id) {
      if (!this.customerSearchTerm) {
        this.customerSearchTerm = s;
      } else if (!this.checkInCustomerSearchTerm) {
        this.checkInCustomerSearchTerm = s;
      }
    }
  }

  @Watch("$user.store") onUserStoreUpdate(s: Store) {
    if (s.id !== this.booking.store?.id) this.booking.store = s;
  }

  @Watch("priceRelatedBookingProperties")
  onBookingPriceUpdate(b: any, p: any) {
    console.log(
      "Booking price related properties updated",
      JSON.stringify(b),
      JSON.stringify(p)
    );
    // if (!b.id) {
    this.updateBookingPrice();
    // }
  }
  @Watch("booking.customer") onBookingCustomerUpdate() {
    if (this.booking.id || !this.booking.type) return;
    if (["play", "food", "party"].includes(this.booking.type)) {
      this.getCustomerCards();
    }
    this.booking.card = null;
    this.paymentGateway = null;
    this.checkExistingBooking();
  }
  @Watch("booking.store") onBookingStoreUpdate(v: Store | string | boolean) {
    // console.log("Booking store changed", JSON.stringify(v), JSON.stringify(p));
    if (v === false) {
      this.booking.store = null;
    }
    if (typeof v !== "string") {
      return;
    }
    this.booking.card = null;
    this.paymentGateway = null;
    if (this.booking.type === "event") {
      this.getEvents();
      if (this.booking.event) this.booking.event = null;
      this.eventSearchTerm = null;
    }
    if (this.booking.type === "gift") {
      this.getGifts();
      if (this.booking.gift) this.booking.gift = null;
      this.giftSearchTerm = null;
    }
  }
  @Watch("customerSearchTerm") onCustomerSearchTermUpdate(t: string) {
    this.searchCustomer(t);
  }
  @Watch("checkInCustomerSearchTerm") onCheckInCustomerSearchTermUpdate(
    t: string
  ) {
    this.searchCustomer(t, true);
  }
  @Watch("eventSearchTerm") onEventSearchTermUpdate(t: string) {
    if (t === null) return;
    this.getEvents(t);
    if (!t) {
      this.booking.event = null;
    }
  }
  @Watch("giftSearchTerm") onGiftSearchTermUpdate(t: string) {
    if (t === null) return;
    this.getGifts(t);
    if (!t) {
      this.booking.gift = null;
    }
  }
  @Watch("priceInPoints") onPriceInPointsUpdate() {
    if (this.priceInPoints && !this.price) {
      this.paymentGateway = "points";
    } else if (!this.priceInPoints && this.paymentGateway === "points") {
      this.paymentGateway = null;
    }
  }

  async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    if (!to.params.id) return next();
    const booking = (await BookingResource.get({
      id: to.params.id
    })) as Booking;
    next(vm => {
      const page = vm as BookingDetail;
      console.log("assign booking");
      page.booking = booking;
    });
  }

  activated() {
    // only for new booking (keep alive)
    eventBus.$on(["stringScanned", "qrcodeScanned"], this.onStringScanned);
  }

  deactivated() {
    // only for new booking (keep alive)
    eventBus.$off(["stringScanned", "qrcodeScanned"], this.onStringScanned);
  }

  async mounted() {
    this.isActivated = true;
    if (this.add) {
      this.booking = {
        id: "",
        customer: null,
        checkInCustomer: null,
        card: null,
        coupon: null,
        event: null,
        gift: null,
        type: (this.$route.params.type as Scene) || Scene.PLAY,
        status: BookingStatus.PENDING,
        date: moment().format("YYYY-MM-DD"),
        checkInAt: moment().format("HH:mm:ss"),
        adultsCount: undefined,
        kidsCount: undefined,
        quantity: undefined,
        bandsPrinted: 0,
        store: this.$user.store,
        payments: []
      };
      console.log("Add booking:", this.booking.type);
      if (
        this.booking.type &&
        [Scene.PARTY, Scene.PLAY, Scene.EVENT].includes(this.booking.type)
      ) {
        this.booking.kidsCount = 1;
        this.booking.adultsCount = 1;
      }
      if (this.booking.type === Scene.GIFT) {
        this.booking.quantity = 1;
      }

      await this.$user;
      const { type } = this.booking;
      if (this.$route.query.customer) {
        this.booking.customer = await UserResource.get({
          id: this.$route.query.customer
        });
      }
      if (type === "event") {
        this.getEvents();
      }
      if (type === "gift") {
        this.getGifts();
        this.booking.quantity = 1;
      }
      // this.updateBookingPrice();
    } else {
      if (!this.booking.id) {
        console.log("booking not loaded, load...");
        this.booking = await BookingResource.get({ id: this.$route.params.id });
      }
      if (this.booking.event) this.eventSearchTerm = this.booking.event.title;
      if (this.booking.gift) this.giftSearchTerm = this.booking.gift.title;
      watchPayments(this.booking.payments || [], this.watchPaymentsState).then(
        async payments => {
          if (payments.length) {
            this.booking = await BookingResource.get({
              id: this.$route.params.id
            });
          }
        }
      );
      if ([Scene.PARTY, Scene.OTHER].includes(this.booking.type as Scene)) {
        console.log("booking is party, get food bookings");
        this.partySubBookings = await BookingResource.query({
          party: this.booking.id
        });
      }
    }
    if (this.booking.customer) {
      this.customerSearchTerm = this.booking.customer.mobile || "";
    }
    if (this.booking.checkInCustomer) {
      this.checkInCustomerSearchTerm =
        this.booking.checkInCustomer.mobile || "";
    }
  }

  destroyed() {
    this.isActivated = false;
    this.watchPaymentsState.enabled = false;
  }
}
